* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  position:fixed;
  width:100%;
  overflow:hidden;


  font-family: Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  height: 100vh;
}

main {
  background: #ffffff;
  flex-grow: 1;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

button {
  background: #333e5a;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}
button:hover {
  filter: brightness(150%);
}

video {
  width: 100%;
  margin: 0 auto;
  border-radius: 0;
}

.btn.btn-primary {
    background: #A61E7B;
}
.btn.btn-link {
  color: #A61E7B;
}